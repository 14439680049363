.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  text-emphasis-color: #000;
  color: #000;
}

.vertical-center {
  position: absolute;
  top: 30vh;
  left: 45%;
}

.text-align-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer !important;
}

.jsontree {
  text-align: left;
}

.jsontree > ul {
  padding: 20px !important;
}

.login-btn {
  border-radius: 10px !important;
  padding: 10px !important;
}

.btn-login-with-google {
  background-color: white;
  border: 1px solid rgba(141, 138, 138, 0.426);
  padding: 10px !important;
  margin: 10px 0;
  color: rgba(31, 31, 31, 0.742);
}

.btn-login-with-google:hover {
  background-color: white;
  border: 1px solid rgba(141, 138, 138, 0.426);
  padding: 10px !important;
  margin: 10px 0;
  color: rgba(31, 31, 31, 0.742);
  cursor: pointer !important;
}

.text-align-right {
  text-align: right;
}

.forgot-password {
  font-size: small;
}

.forgot-password:hover {
  cursor: pointer;
}

/* *{
  color: rgba(31, 31, 31, 0.742);
} */

.transparent-header {
  position: absolute;
  padding: 2em;
  width: 80%;
  margin: 1em 9.5em;
  background: #ffffff2f;
  border-radius: 9px;
  box-shadow: 1px 2px 85px 5px #000;
  text-align: start;
}

.verticalCenter {
  margin-top: 8em;
}

.text-left {
  text-align: start;
}

.icon {
  fill: #7047eb !important;
}

.active {
  background: #7047eb33;
  border-left: 5px solid #7047eb;
}

.btn-main {
  background: #7047eb;
  color: white;
  border-radius: 10em;
  padding: 0.4em 2em;
}

.btn-main:hover {
  background: #7258bf;
  color: white;
}

.mode-cirlce {
  padding: 0.3em;
  border-radius: 50%;
  background-color: #000;
}

.page-header {
  font-weight: 600;
}

.first-dashboard-row {
  background-color: #ece0fb;
  border-radius: 16px;
  color: rgb(32, 10, 105);
}

.btn-purple {
  background-color: #431a9e;
}

.btn-purple:hover {
  background-color: #431a9e;
  box-shadow: 0px 0px 6px 1px #441a9e8f;
}

.margin-0-auto {
  margin: 0 auto;
}

.dashboard-box {
  border-radius: 8px;
  padding: 1.2em;
}

.dashboard-box-btn {
  background-color: #00000032;
  border-radius: 20em;
  padding: 1em;
}

.balance-box {
  background: #ff6633;
}

.expense-box {
  background: #8833ff;
}

.project-box {
  background: #29cc39;
}

.get-span {
  color: #29cc39;
  font-size: small;
}

.post-span {
  color: #c1791a;
  font-size: small;
}

.justify-content-right {
  justify-content: right;
  display: flex;
}


/* css from external sources
*/
